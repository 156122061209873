@import url(./assets/fonts/style.css);
body {
  margin: 0;
  background-color: #000000;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}
a {
  color: white;
}

:focus {
  outline: 0;
}

input[type="search"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}

[hidden] {
  display: none;
}

html {
  font-size: 100%; /* 1 */
  -webkit-text-size-adjust: 100%; /* 2 */
  -ms-text-size-adjust: 100%; /* 2 */
}

a:active,
a:hover {
  outline: 0;
}

button,
html input[type="button"], /* 1 */
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button; /* 2 */
  cursor: pointer; /* 3 */
  overflow: visible; /* 4 */
}

input[type="search"] {
  -webkit-appearance: textfield; /* 1 */
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box; /* 2 */
  box-sizing: content-box;
}

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

.container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  max-width: 430px;
  margin-left: auto;
  margin-right: auto;
  overflow-x: hidden;
}

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-image: linear-gradient(
    rgba(19, 231, 100, 1),
    rgba(248, 248, 37, 1)
  );
  border-radius: 0px 0px 16px 16px;
  padding: 24px 0px;
  gap: 24px;
  padding-top: 0px;
}

.header > .title {
  font-weight: bold;
  line-height: 51px;
  font-size: 60px;
  text-align: center;
  color: white;
  font-family: "Sharp Grotesk 15";
  text-shadow: 4px 4px #000;
}

.header > .logo {
  width: 100%;
  max-width: 150px;
  max-height: 150px;
  height: 100%;
}

.header > .subtitle {
  font-size: 30px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: center;
  font-family: "Sharp Grotesk 15";
  padding-left: 24px;
  padding-right: 24px;
}

.header > div > .voucherBox {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #2f302f;
  padding: 20px 12px;
  border-radius: 12px 12px;
  align-items: center;
  overflow: hidden;
  gap: 16px;
}

.header > div {
  width: 100%;
  padding-left: 24px;
  padding-right: 24px;
}

.header > div > .voucherBox > span {
  color: white;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
}

.header > div > .voucherBox > p {
  font-size: 24px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.1em;
  text-align: center;
  color: #13e764;
  font-family: "Sharp Grotesk 15";
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 24px 24px;
  gap: 24px;
}

.content > .redeemBox {
  display: flex;
  flex-direction: row;
  width: 100%;
  background-color: #2f302f;
  padding: 20px 12px;
  padding-bottom: 0px;
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 12px;
  border-radius: 12px 12px;
  align-items: center;
  gap: 16px;
  position: relative;
}

.content > .redeemBox > img {
  width: 72px;
  height: 80px;
}

.content > .redeemBox > p {
  color: #ffffff;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  margin-bottom: 12px;
  text-align: center;
  width: 100%;
}

.divider {
  width: 100%;
  height: 1px;
  border-radius: 8px 8px;
  opacity: 50%;
}

.content > .redeemInstructions {
  display: flex;
  flex-direction: column;
  text-align: left;
  width: 100%;
}

.content > .redeemInstructions > .title {
  font-size: 24px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: white;
  margin-bottom: 16px;
  font-family: "Sharp Grotesk 15";
}

.content > .redeemInstructions > span {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 0px;
  color: white;
  font-family: "Montserrat", sans-serif;
}

.content > .redeemInstructions > span > * {
  color: white;
}
.content > .redeemInstructions > span > p {
  margin-bottom: 8px;
}

.content > .redeemInstructions > span > p:last-child {
  margin-bottom: 0px;
}

.content > button {
  padding: 14px 30px 14px 30px;
  border-radius: 100px;
  gap: 8px;
  font-size: 12px;
  font-weight: 700;
  line-height: 12px;
  letter-spacing: 0.04em;
  text-align: center;
  font-family: "Montserrat", sans-serif;
  outline: none;
  border: none;
  color: black;
  background-color: white;
}

.loading-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #000;
  z-index: 1099;
  transition: none;
}

.loading-wrapper.done {
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.6s ease 1s;
}

.loading-spinner {
  width: 50px;
  height: 50px;
  border-top: 5px solid #0c212d;
  border-right: 5px solid #0c212d;
  border-bottom: 5px solid #0c212d;
  border-left: 5px solid #fff;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  -webkit-animation: spinner 700ms infinite linear;
  -moz-animation: spinner 700ms infinite linear;
  -ms-animation: spinner 700ms infinite linear;
  -o-animation: spinner 700ms infinite linear;
  animation: spinner 700ms infinite linear;
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.terms-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 0px 0px;
  gap: 24px;
}

.terms-content > .terms {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 24px 24px;
  border-radius: 12px 12px;
  align-items: center;
  gap: 16px;
  position: relative;
  word-break: break-word;
}

.terms-content > .terms > div > div {
  color: #ffffff;
  font-family: "Montserrat", sans-serif;
  margin-bottom: 12px;
  text-align: left;
  width: 100%;
}

.terms-content > .terms > div > div * {
  font-family: "Montserrat", sans-serif !important;
  margin-bottom: 16px;
  text-align: left;
  width: 100%;
  line-height: 1.4 !important;
  font-weight: 600;
}

.terms-content > .terms > div > div > ul,
ol > li {
  margin-bottom: 12px;
}

.terms-content > .terms > div > div > ul,
ol {
  padding-left: 20px;
}

.terms-content > .terms > div > h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Sharp Grotesk 15" !important;
  line-height: 1 !important;
  margin-bottom: 24px;
  color: white;
}

.generic-title {
  font-size: 60px;
  font-family: "Sharp Grotesk 15" !important;
  line-height: 85% !important;
  color: #ffffff;
  text-align: left;
  width: 100%;
  padding-left: 24px;
  text-transform: uppercase;
}

.generic-description {
  font-family: "Montserrat", sans-serif;
  padding-left: 24px;
  font-size: 16px;
  color: #ffffff;
  width: 100%;
  font-weight: 600;
  line-height: 140%;
}

.generic-image {
  width: 100%;
}
