@font-face {
  font-family: "Sharp Grotesk 25";
  src: url("./SharpGroteskBold25.woff") format("woff"),
    url("./SharpGroteskBold25.woff") format("woff");
  font-weight: bold;
}

@font-face {
  font-family: "Sharp Grotesk 20";
  src: url("./SharpGroteskBold20.woff") format("woff"),
    url("./SharpGroteskBold20.woff") format("woff");
  font-weight: bold;
}

@font-face {
  font-family: "Sharp Grotesk 15";
  src: url("./SharpGroteskBold15.woff") format("woff"),
    url("./SharpGroteskBold15.woff") format("woff");
  font-weight: bold;
}

@font-face {
  font-family: "Sharp Grotesk 10";
  src: url("./SharpGroteskBold10.woff") format("woff"),
    url("./SharpGroteskBold10.woff") format("woff");
  font-weight: bold;
}

@font-face {
  font-family: "Sharp Grotesk 5";
  src: url("./SharpGroteskBold5.woff") format("woff"),
    url("./SharpGroteskBold5.woff") format("woff");
  font-weight: bold;
}
